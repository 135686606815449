:root{
  --primary: #00909e; /*dark background color*/
  --secondary: #151d26; /*Black*/
  --third: #81B29A; 
  --fourth: #1f374d; 
  --fifth: #f2f2f2; /*light text*/
  --bs-primary: #3D405B;
  --bs-blue: #3D405B;
  --bs-btn-bg : #3D405B;

}

#date-container{
	position: fixed;
	top: 6vh; 
	min-width: 100vw; 
	max-width: 100vw;
}
.client-info{
	background-color: var(--fifth)!important;
	border-radius: 10px;
	border-style: hidden;
	margin-left: -0px;
	margin-bottom: 10px;
	width: 85vw; 
	overflow-x: scroll;
}

@font-face {
  font-family: 'goudyheapregular';
  src: url('./Media/Fonts/goudyHeap/goudyheap-webfont.woff2') format('woff2'),
       url('./Media/Fonts/goudyHeap/goudyheap-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.footer{
	height: 10vh; 
	background-color: var(--fourth);
	color: white;
	font-size: 12px; 
	text-align: center;
	font-family: 'goudyheapregular';
}
.stop-card{
	background-color: green. !important;

}

.accordion-button{
	border-style: solid !important;
	border-color: var(--fourth) !important;
	border-width: 0.5px;
	border-radius: 0px; 
}
.custompin{
	height:30px; 
	width: 30px; 
	background-color: red;
	color: white;
	border-radius: 50px;
	padding: 50%; 
}

#mymap{
	height: 50vh !important;


}
#text{
	height: 20vh !important;
	overflow-y: scroll;

}
#numberPad {
	background-color: blue;
}
.button-pad {
	min-width: 3em;
	height: 40px;
	font-size: 1.2em;
	background-color: white;
	color: black;
	border-style: solid;
	border-radius: 5px;
	border-color: black;
	cursor: pointer;
	transition: background-color 0.3s;
}

.button-pad:active {
	background-color: lightgrey;
	transform: translateY(2px); /* Move the button down slightly */
}

.lowerTabs {
	min-height: 7vh;
	max-height: 7vh;
}
.main-body {
	min-height: 90vh;
	max-height: 90vh;
	min-width: 110vw;
	max-width: 110vw;
	overflow-y: scroll;
	margin-left: -15px;
	background-color: rgba(230,230,230,0.7);
	padding-top: 20px;
	padding-bottom: 50vh;
	padding-right: 3em;
	padding-left:1em ;
}
.noMargin {
	padding: 0 !important;
}
.whole-app {
	min-height: 100vh;
	max-height: 100vh;
	min-width: 100vw;
	max-width: 100vw;

	overflow-y: hidden;
	overflow-x: hidden;
}

body {
	min-height: 100vh;
	max-height: 100vh;
	min-width: 100vw;
	max-width: 100vw;
	overflow-y:hidden; 
	overflow-x: hidden; 
}
#greyed{
	background-color: lightgrey !important;
}